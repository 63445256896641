import * as React from "react";
import { PropertyBookBarProps } from "./typings";
import { PropertyStickyBookBar } from "@shared-ui/lodging-property-offers";
import { PropertyBexApiWrapper } from "src/components/shared/BexApiWrapper/PropertyBexApiWrapper";
import { Viewport, ViewSmall } from "@shared-ui/viewport-context";
import { useSignalPublisher } from "@shared-ui/signal-events-context";
import { PropertyBookBarSignalNames } from "./utils/constant";
import { useRefContext } from "@shared-ui/ref-context";

const WrappedStickyBookBar = PropertyBexApiWrapper(PropertyStickyBookBar);

export const PropertyBookBar = (props: PropertyBookBarProps) => {
  const { context, templateComponent } = props;
  const { registerTarget } = useRefContext();
  const { publishSignal } = useSignalPublisher();
  const stickyBarConatinerRef = registerTarget<HTMLDivElement>("stickyBookbarContainer");
  /* istanbul ignore if */
  if (!templateComponent) {
    return null;
  }

  const {
    config: { hidePrice },
  } = templateComponent;

  const handleContainerClick = (event: any) => {
    if (event.target.id === "stickyBookbarContainer") {
      publishSignal({ payload: {}, type: PropertyBookBarSignalNames.CHECK_AVAILABILITY_CALL_TO_ACTION_BUTTON_CLICK });
    }
  };
  return (
    <>
      <Viewport>
        <ViewSmall>
          <div
            id="stickyBookbarContainer"
            ref={stickyBarConatinerRef}
            data-testid="stickyBookbarContainer"
            onClick={handleContainerClick}
          >
            <WrappedStickyBookBar
              context={context}
              hidePrice={hidePrice}
              clickTargetRef="stickyBookbarContainer"
              scrollSectionRef="lodgingSearchFormSection"
              hideSectionRef="lodgingSearchFormSection"
            />
          </div>
        </ViewSmall>
      </Viewport>
    </>
  );
};

export default PropertyBookBar;
